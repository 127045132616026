import React from 'react'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import CTAArea from '../containers/global/cta-area/section-one'
import Text from '../components/ui/text'
import {Col, Container, Row} from '../components/ui/wrapper'
import SectionTitle from "../components/ui/section-title";
import {data} from '../containers/contact-us/data';
import BoxIcon from '../components/box-icon/layout-one';


const ContactUsPage = ({ pageContext, location }) => (
    <Layout location={location}>
        <SEO title="Contact Us"/>
        <Header/>
        <PageHeader
            pageContext={pageContext} 
            location={location}
            title="Contact Us"
        />
        <main className="site-wrapper-reveal">
          <Container mt="50px" mb="50px">
            <Row>
              <Col lg={12} mb="50px">
                <SectionTitle
                  mb="40px"
                  title="To make requests for further information, <br/> contact us via our social channels."
                />
                <Text style={{ textAlign: 'center'}}>We just need a couple of hours! No more than 2 working days since receiving your issue ticket</Text>
              </Col>
            </Row>
            <Row>
              {data.map((item, i) => (
                <Col lg={3} md={6} key={`box-icon-1-${i}`}>
                  <BoxIcon
                    boxStyles={{ mt: "30px"}}
                    id={item.id}
                    title={item.title}
                    desc={item.desc}
                    path={item.path}
                    icon={item.icon}
                    buttonTitle={item.button}
                  />
                </Col>
              ))}
            </Row>
          </Container>
            <CTAArea/>
        </main>
        <Footer/>
    </Layout>
  )
   
  export default ContactUsPage