import iconOne from "../../data/images/icons/linea-music-headphones.svg"
import iconTwo from "../../data/images/icons/linea-ecommerce-money.svg"
import iconThree from "../../data/images/icons/linea-basic-heart.svg"
import iconFour from "../../data/images/icons/linea-basic-spread-text-bookmark.svg"


export const data = [
  {
    id: "box-icon-1-1",
    title: "Contact a specialist",
    desc: "Wondering if Unbounce is the right tool for your business? Chat with our team to see if there’s a fit.",
    path: "/",
    icon: iconOne,
    button: "Let's Chat"
  },
  {
    id: "box-icon-1-2",
    title: "Get support",
    desc: "Looking for help in the builder or have questions about your existing account? Chat with a real human from our award-winning customer success team, or see our Help Center.",
    path: "/",
    icon: iconTwo,
    button: 'Get Support'
  },
  {
    id: "box-icon-1-3",
    title: "Media inquiries",
    desc: "We love working with journalists to share compelling stories. Send us a note and our PR and Communications Manager will be in touch.",
    path: "/",
    icon: iconThree,
    button: 'Get in Touch'
  },
  {
    id: "box-icon-1-4",
    title: "Partnership inquiries",
    desc: "We’re into co-marketing with awesome brands. Fill out the form here, and our Partnerships Manager will circle back.",
    path: "/",
    icon: iconFour,
    button: "Let's Chat"
  },
]